import React from 'react'
import { SwedishCookiesInfo } from '@accodeing/gatsby-theme-heimr'

import Layout from 'components/Layout'

const Cookies = ({ data }) => (
  <Layout seo={{ title: 'Cookies' }}>
    <oma-grid-row>
      <SwedishCookiesInfo />
    </oma-grid-row>
  </Layout>
)

export default Cookies
